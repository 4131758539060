<script setup>
	import { orderData } from '@/composables/helpers/orderData.js';

	const { awards } = defineProps({
		awards: {
			type: Array,
			required: false,
		},
	});

	// awards query
	const idsArray = computed(() => {
		let list = [];
		if (!awards.length) return list;

		awards.forEach((award) => {
			if (award?.award?.id) {
				list.push(award.award.id);
			}
		});

		return list;
	});
	const fetchAwards = await $fetch('/api/awards-data', {
		params: {
			'query.id.$in': [idsArray.value],
			// limit: idsArray.value.length,
			fields: 'id,name,data.year,data.text,data.image',
		},
	});

	const orderedAwards = ref([]);
	if (fetchAwards?.data) {
		orderedAwards.value = orderData(fetchAwards?.data, idsArray.value);
	}
</script>

<template>
	<ul class="block-awards list" v-if="orderedAwards?.length" :data-columns="orderedAwards?.length">
		<li v-for="(item, index) in orderedAwards" :key="index" class="item">
			<MessImage v-if="item?.data.image" :src="item?.data.image" />
			<MessHtml :html="item?.data.text" tag="h4" class="h6 item-text" />
		</li>
	</ul>
</template>

<style lang="scss">
	.block-awards.list {
		display: flex;
		flex-direction: column;
		background-color: var(--white);
		padding: 15px;
		gap: 15px;

		// Design specifically requested the 600px breakpoint.
		@media (min-width: 600px) {
			padding: 30px;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		&[data-columns='3'] {
			@media (min-width: 600px) {
				flex-direction: row;
				flex-wrap: wrap;
			}
			.item {
				@media (min-width: $phone) {
					flex: 0 0 calc(33.33% - 15px);
					.mess-image {
						flex: 0 0 40%;
					}
				}
				@media (min-width: 600px) {
					max-width: 360px;
					flex: 0 0 calc(33.33% - 30px);
					.mess-image {
						flex: 0 0 25%;
					}
				}
			}
		}

		&[data-columns='4'] {
			@media (min-width: 600px) {
				flex-direction: row;
				flex-wrap: wrap;
			}

			.item {
				@media (min-width: $phone) {
					flex: 0 0 calc(50% - 15px);
				}
				@media (min-width: $mobile) {
					flex: 0 0 calc(25% - 15px);
				}
			}
		}

		.item {
			display: flex;
			align-items: center;

			.mess-image {
				flex: 0 0 20%;
				max-width: unset;
				margin-right: 1rem;
				@media (min-width: $phone) {
					flex: 0 0 25%;
					max-width: 100px;
				}

				img {
					border-radius: 100%;
					aspect-ratio: 1 / 1;
				}
			}

			.item-text {
				margin-bottom: 0;
			}
		}
	}
</style>
